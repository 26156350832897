import styles from './FooterTrustLogos.module.scss'
import ThemeImage from '@/components/elements/media/ThemeImage/ThemeImage'
import { Col } from 'react-bootstrap'

const FooterTrustLogos = () => {
  return (
    <Col className={`${styles.root}`}>
      <a target={'_blank'} href="https://www.srv.ch/" rel="noreferrer" data-tr-click="footer:trust_icon:click" data-tr-trust_icon_name="SRV">
        <ThemeImage src={'images/logos/logo-srv.png'} height={37} alt="SRV Logo" theme={'kultour'} />
      </a>
      <a target={'_blank'} href="https://tourcert.org/" rel="noreferrer" data-tr-click="footer:trust_icon:click" data-tr-trust_icon_name="TourCert">
        <ThemeImage src={'images/logos/logo-tourcert-white.png'} height={43} alt="TourCert Logo" theme={'kultour'} />
      </a>
      <a target={'_blank'} href="https://www.astag.ch/" rel="noreferrer" data-tr-click="footer:trust_icon:click" data-tr-trust_icon_name="ASTAG">
        <ThemeImage src={'images/logos/logo-astag.png'} height={36} alt="ASTAG Logo" theme={'kultour'} />
      </a>
      <a
        target={'_blank'}
        href="https://www.garantiefonds.ch/"
        rel="noreferrer"
        data-tr-click="footer:trust_icon:click"
        data-tr-trust_icon_name="Reisegarantie"
      >
        <ThemeImage src={'images/logos/logo-reisegarantie-white.png'} height={38} alt="Reisegarantie Logo" theme={'kultour'} />
      </a>
    </Col>
  )
}

export default FooterTrustLogos
